import { isEmpty } from 'ramda'

export default async ({ redirect, store, query }) => {
  const token = query.token ? JSON.parse(query.token) : false
  const isUserLoggedIn = JSON.parse(
    window.localStorage.getItem('sessions') || '[]'
  )
  const isAddUser = JSON.parse(
    window.sessionStorage.getItem('add_user') || 'false'
  )
  if (!isEmpty(isUserLoggedIn) && isAddUser !== true && !token) {
    // Check if the user is already logged in
    if (!store.getters['auth/check']) {
      try {
        // If not logged in, call API to fetch user information
        await store.dispatch('auth/fetchUser')

        // After API call, check login status again
        // If still not logged in, remove session information from localStorage
        if (!store.getters['auth/check']) {
          window.localStorage.removeItem('sessions')
          return false
        }
      } catch (error) {
        // If an error occurs during API call, remove session information
        // and return false to redirect user to login page
        window.localStorage.removeItem('sessions')
        return false
      }
    }

    if (store.getters['auth/check']) {
      return redirect({ name: 'dashboard' })
    }
  }
  return false
}
