export const state = () => ({
  departmentOptions: [],
  employeeGroupOptions: [],
  positionOptions: [],
  applicationCategoryOptions: [],
  worktimeRegisterCategoriesMap: {}
})

export const getters = {
  departmentOptions: (state) => state.departmentOptions,
  employeeGroupOptions: (state) => state.employeeGroupOptions,
  positionOptions: (state) => state.positionOptions,
  applicationCategoryOptions: (state) => state.applicationCategoryOptions,
  worktimeRegisterCategoriesMap: (state) => state.worktimeRegisterCategoriesMap
}

export const mutations = {
  SET_DEPARTMENT_OPTIONS(state, payload) {
    state.departmentOptions = payload
  },
  SET_EMPLOYEE_GROUP_OPTIONS(state, payload) {
    state.employeeGroupOptions = payload
  },
  SET_POSITION_OPTIONS(state, payload) {
    state.positionOptions = payload
  },
  SET_APPLICATION_CATEGORY_OPTIONS(state, payload) {
    state.applicationCategoryOptions = payload
  },
  SET_WORKTIME_REGISTER_CATEGORIES_MAP(state, payload) {
    state.worktimeRegisterCategoriesMap = payload
  }
}

export const actions = {
  setDepartmentOptions({ commit }, payload) {
    commit('SET_DEPARTMENT_OPTIONS', payload)
  },
  setEmployeeGroupOptions({ commit }, payload) {
    commit('SET_EMPLOYEE_GROUP_OPTIONS', payload)
  },
  setPositionOptions({ commit }, payload) {
    commit('SET_POSITION_OPTIONS', payload)
  },
  setApplicationCategoryOptions({ commit }, payload) {
    commit('SET_APPLICATION_CATEGORY_OPTIONS', payload)
  },
  setWorktimeRegisterCategoriesMap({ commit }, payload) {
    commit('SET_WORKTIME_REGISTER_CATEGORIES_MAP', payload)
  }
}
