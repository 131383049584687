/**
 * Resize an image file to a target size in MB
 * @param {File} file - The image file to resize 
 * @param {number} targetSizeMB - Target file size in MB
 * @returns {Promise<File>} - Resized file
 */
export const resizeImage = async (file, targetSizeMB) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        let base64 = e.target.result;
        let base64Size = Math.ceil((base64.length * 0.75));
        const maxFileSize = targetSizeMB * 1024 * 1024;
        
        // Calculate initial scale factor based on size proportion
        let scaleFactor = Math.sqrt(maxFileSize / base64Size);
        
        // Create and load image
        const image = new Image();
        await new Promise(r => {
          image.onload = r;
          image.src = base64;
        });

        // Setup canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = image.width * scaleFactor;
        canvas.height = image.height * scaleFactor;

        while (base64Size > maxFileSize) {
          // Create canvas and resize
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          
          base64 = canvas.toDataURL(file.type, 1.0);
          base64Size = Math.ceil((base64.length * 0.75));
          
          scaleFactor = Math.sqrt(maxFileSize / base64Size);
          canvas.width *= scaleFactor;
          canvas.height *= scaleFactor;
        }

        // Convert base64 back to File object
        const base64Content = base64.split(',')[1];
        const blob = new Blob([Uint8Array.from(atob(base64Content), c => c.charCodeAt(0))], { type: file.type });
        const resizedFile = new File([blob], file.name, { type: file.type });

        resolve(resizedFile);
      } catch (error) {
        reject(error);
      }
    };
    reader.readAsDataURL(file);
  });
};

export const getSizeMB = (bytes) => {
  return (bytes / (1024 * 1024)).toFixed(2);
};