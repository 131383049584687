import Vue from 'vue'

Vue.component('monaco-editor', {
  props: {
    value: {
      type: String,
      default: ''
    },
    language: {
      type: String,
      default: 'javascript'
    },
    theme: {
      type: String,
      default: 'vs-light'
    },
    options: {
      type: Object,
      default: () => ({
        fontSize: 14,
        minimap: { enabled: false },
        scrollBeyondLastLine: false,
        wordWrap: 'on',
        lineNumbers: 'off',
        glyphMargin: false,
        folding: false,
        contextmenu: false,
        automaticLayout: true,
        tabSize: 2,
        // Disable all line decorations
        renderLineHighlight: 'none',
        renderIndentGuides: false,
        overviewRulerBorder: false,
        hideCursorInOverviewRuler: true,
        overviewRulerLanes: 0,
        scrollbar: {
          vertical: 'hidden',
          horizontal: 'hidden',
        },
        suggest: {
          showFunctions: false,
          showConstructors: false,
          showFields: false,
          showVariables: false,
          showClasses: false,
          showStructs: false,
          showInterfaces: false,
          showModules: false,
          showProperties: false,
          showEvents: false,
          showOperators: false,
          showUnits: false,
          showValues: false,
          showConstants: false,
          showEnums: false,
          showEnumMembers: false,
          showKeywords: false,
          showWords: false,
          showColors: false,
          showFiles: false,
          showReferences: false,
          showFolders: false,
          showTypeParameters: false,
          showSnippets: false,
          showUsers: false
        }
      })
    }
  },
  data() {
    return {
      editor: null,
      monaco: null,
      loaded: false
    }
  },
  watch: {
    value(newValue) {
      if (this.editor && newValue !== this.editor.getValue()) {
        this.editor.setValue(newValue)
      }
    },
    language(newVal) {
      if (this.editor && this.monaco) {
        this.monaco.editor.setModelLanguage(this.editor.getModel(), newVal)
      }
    },
    theme(newVal) {
      if (this.monaco) {
        this.monaco.editor.setTheme(newVal)
      }
    },
    options: {
      deep: true,
      handler(newVal) {
        if (this.editor) {
          this.editor.updateOptions(newVal)
        }
      }
    }
  },
  render(h) {
    return h('div', {
      style: {
        width: '100%',
        height: '100%',
        minHeight: '120px'  // Updated for PayrollFormula use case
      },
      ref: 'container'
    })
  },
  mounted() {
    if (process.client) {
      this.initMonaco()
    }
  },
  beforeDestroy() {
    if (this.editor) {
      document.removeEventListener('mousedown', this.handleClickOutside)
      this.editor.dispose()
    }
  },
  methods: {
    async initMonaco() {
      await this.loadMonaco()
      
      if (window.monaco) {
        this.monaco = window.monaco
        const defaultOptions = {
          value: this.value,
          language: this.language,
          theme: this.theme,
          automaticLayout: true,
          minimap: { enabled: false },
          scrollBeyondLastLine: false,
          lineNumbers: 'on',
          glyphMargin: false,
          folding: true,
          wordWrap: 'on'
        }

        this.editor = this.monaco.editor.create(
          this.$refs.container,
          Object.assign(defaultOptions, this.options)
        )

        // Add blur event listener
        this.editor.onDidBlurEditorWidget(() => {
          this.$emit('blur')
        })

        // Add focus event listener
        this.editor.onDidFocusEditorWidget(() => {
          this.$emit('focus')
        })

        this.editor.onDidChangeModelContent(() => {
          const value = this.editor.getValue()
          if (value !== this.value) {
            this.$emit('input', value)
            this.$emit('change', value)
          }
        })

        // Handle clicks outside editor
        document.addEventListener('mousedown', this.handleClickOutside)
        
        this.loaded = true
        this.$emit('monaco-loaded')
      }
    },
    
    loadMonaco() {
      return new Promise((resolve) => {
        if (window.monaco) {
          resolve(window.monaco)
          return
        }

        window.require = { paths: { vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.39.0/min/vs' } }
        
        const script = document.createElement('script')
        script.src = 'https://cdn.jsdelivr.net/npm/monaco-editor@0.39.0/min/vs/loader.js'
        script.onload = () => {
          window.require(['vs/editor/editor.main'], () => {
            resolve(window.monaco)
          })
        }
        
        document.head.appendChild(script)
      })
    },

    handleClickOutside(event) {
      const editorElement = this.$refs.container
      const suggestionElement = event.target.closest('.parameter-suggestions')
      
      // Don't trigger blur if clicking suggestions
      if (suggestionElement) {
        return
      }

      // Check if click was outside editor
      if (editorElement && !editorElement.contains(event.target)) {
        this.$emit('blur', event)
      }
    },

    // Add helper method to get Monaco instance
    getMonaco() {
      return this.editor
    }
  }
})