import Cookies from 'js-cookie'
import User from '@/dao/User'
import Constant from '@/plugins/Constant'
import { pluck } from 'ramda'
import Helper from '~/util/helper'

// state
export const state = () => ({
  client: null,
  clientWorkflowSetting: {
    advanced_permission_flow: false,
    payslip_complaint: false,
    advanced_approval_flow: false,
    enable_early_leave_request: false,
    enable_leave_request: false,
    enable_overtime_request: false,
    show_timesheet_for_customer: false,
    enable_training_seminar: false,
    enable_evaluate: false,
    enable_recruit_function: false,
    enable_wifi_checkin: false,
    enable_show_hour_instead_of_day: false,
    enable_create_supplier_for_individual: false,
    enable_makeup_request_form: false,
    enable_timesheet_min_time_block: false,
    enable_calculator_timesheet: false,
    authorized_leave_woman_leave: false,
    template_export: '',
  },
  clientEmployees: [],
  user: null,
  token: null,
  hasJobboardAssignment: [],
  hasEvaluationAssignment: [],
  knowledgeQuestions: [],
  provinceData: {},
  permissionsTemp: [],
})

// getters
export const getters = {
  client: (state) => state.client,
  user: (state) => state.user,
  clientWorkflowSetting: (state) => state.clientWorkflowSetting,
  token: (state) => state.token,
  check: (state) => state.user !== null,
  permissions: (state) => state.permissions,
  permissionsTemp: (state) => state.permissionsTemp,
  hasJobboardAssignment: (state) => state.hasJobboardAssignment,
  hasEvaluationAssignment: (state) => state.hasEvaluationAssignment,
  knowledgeQuestions: (state) => state.knowledgeQuestions,
  provinceData: (state) => state.provinceData,
}

// mutations
export const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
  },

  FETCH_USER_SUCCESS(state, { me }) {
    state.user = me

    state.client = me.client
    state.clientWorkflowSetting = me.client.clientWorkflowSetting || {}

    if (state.user.clientEmployee) {
      state.user.role = state.user.clientEmployee.role
      state.user.employee_id = state.user.clientEmployee.id
      state.user.avatar = state.user.clientEmployee.avatar_path
      state.user.groups =
        state.user.clientEmployee.clientEmployeeGroupAssignment.length > 0
          ? pluck(
              'clientEmployeeGroup',
              state.user.clientEmployee.clientEmployeeGroupAssignment
            )
          : [{ id: '0', name: 'Default' }]
    }

    if (state.user && !state.user.working_hours) {
      state.user.working_hours = 8
    }

    if (me.permissions) {
      const p = JSON.parse(me.permissions)
      state.permissionsTemp = p
      state.permissions = p.map((v) => v.name)
      state.permissions = Helper.groupPermissions(state.user, state.permissions)
    }

    if (state.user && !state.user.system_variables) {
      state.user.system_variables = Constant.SYSTEM_VARIABLES
    }

    if (typeof rg4js === 'function') {
      rg4js('setUser', {
        identifier: me.id,
        isAnonymous: false,
        email: me.email,
        fullName: me.name + ' (' + me.client.code + ')',
      })
    }
  },

  FETCH_USER_FAILURE(state) {
    state.token = null
  },

  LOGOUT(state) {
    state.user = null
    state.token = null
  },

  UPDATE_USER(state, { user }) {
    state.user = user
  },

  updateUserFields(state, user) {
    if (!state.user) {
      state.user = {}
    }
    state.user = Object.assign(state.user, user)
  },

  insertClientEmployee(state, clientEmployee) {
    state.clientEmployees.push(clientEmployee)
  },

  HAS_JOBBOARD_ASSIGNMENT(state, checked) {
    state.hasJobboardAssignment = checked
  },

  UPDATE_KNOWLEDGE_QUESTIONS(state, knowledgeQuestions) {
    state.knowledgeQuestions = knowledgeQuestions
  },

  UPDATE_PROVINCE_DATA(state, provinces) {
    state.provinceData = provinces
  },

  HAS_EVALUATION_ASSIGNMENT(state, checked) {
    state.hasEvaluationAssignment = checked
  },
}

const cleanCookie = () => {
  Cookies.remove('token')
  Cookies.remove('refresh_token')
  Cookies.remove('expires_in')
}

// actions
export const actions = {
  switchFirstAvailableSession({ commit, dispatch }) {
    const sessions = JSON.parse(localStorage.getItem('sessions'))
    if (sessions.length) {
      dispatch('switchSession', {
        sessionId: sessions[0].id,
      })
    } else {
      this.$router.push({ name: 'dang-nhap' })
    }
  },
  switchSession({ commit, dispatch }, { sessionId }) {
    const sessions = JSON.parse(localStorage.getItem('sessions'))
    const ss = sessions.find((v) => v.id === sessionId)
    if (ss) {
      dispatch('saveToken', {
        ...ss,
      })
      ss.is_current = true
      window.localStorage.setItem('sessions', JSON.stringify(sessions))
      window.location.assign('/dashboard')
    } else {
      this.$router.push({ name: 'dang-nhap' })
    }
  },
  saveToken(
    { commit, dispatch },
    { access_token, refresh_token, expires_in, remember }
  ) {
    // let options = {}
    // if (remember) {
    //   options = { expires: 365 }
    // }
    const options = { expires: 365 }
    Cookies.set('token', access_token, options)
    Cookies.set('refresh_token', refresh_token, options)
    Cookies.set('expires_at', new Date().getTime() / 1000 + expires_in, options)
    Cookies.set('remember', true, options)
  },

  async fetchUser({ commit, dispatch }) {
    try {
      const { data } = await User.me()
      commit('FETCH_USER_SUCCESS', data)
      commit('lang/SET_LOCALE', data.me.prefered_language, { root: true })
      dispatch('fetchSettings')
    } catch (e) {
      // Clean up broken session
      const sessions = JSON.parse(localStorage.getItem('sessions'))
      if (Array.isArray(sessions)) {
        const usedToken = Cookies.get('token')
        const newSessions = sessions.filter((v) => v.access_token !== usedToken)
        localStorage.setItem('sessions', JSON.stringify(newSessions))
      }
      commit('FETCH_USER_FAILURE')
      dispatch('switchFirstAvailableSession')
    }
  },

  async fetchSettings({ commit }) {
    Helper.gqlSingleQuery('Setting', 'getAll').then(({ data }) => {
      commit('settings/SET_SETTINGS', data, { root: true })
    })
  },

  updateUser({ commit }, payload) {
    commit('UPDATE_USER', payload)
  },

  updateUserFields({ commit }, payload) {
    commit('updateUserFields', payload)
  },

  cacheClientEmployee({ commit }, payload) {
    commit('insertClientEmployee', payload)
  },

  async logout({ commit }) {
    try {
      await User.logout()
    } catch (e) {}
    cleanCookie()
    commit('LOGOUT')
  },

  updateProvinceData({ commit }, payload) {
    commit('UPDATE_PROVINCE_DATA', payload)
  },
}
