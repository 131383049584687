export const state = () => ({
  selectedEmployees: [],
  worktimeRegisterCategoriesMap: {},
  applicationCategoryOptions: [],
  departmentOptions: [],
  employeeGroupOptions: [],
  positionOptions: [],
})

export const getters = {
  selectedEmployees: (state) => state.selectedEmployees,
  worktimeRegisterCategoriesMap: (state) => state.worktimeRegisterCategoriesMap,
  applicationCategoryOptions: (state) =>
    state.applicationCategoryOptions,
  departmentOptions: (state) => state.departmentOptions,
  employeeGroupOptions: (state) => state.employeeGroupOptions,
  positionOptions: (state) => state.positionOptions,
}

export const mutations = {
  SET_SELECTED_EMPLOYEES(state, selectedEmployees) {
    state.selectedEmployees = selectedEmployees
  },
  CLEAR_SELECTED_EMPLOYEES(state) {
    state.selectedEmployees = []
  },
  ADD_EMPLOYEE(state, employee) {
    state.selectedEmployees.push(employee)
  },
  REMOVE_EMPLOYEE(state, employeeId) {
    state.selectedEmployees = state.selectedEmployees.filter(
      (emp) => emp.id !== employeeId
    )
  },
  ADD_EMPLOYEE_BATCH(state, employees) {
    state.selectedEmployees = [...state.selectedEmployees, ...employees]
  },
  REMOVE_EMPLOYEE_BATCH(state, employeeIds) {
    state.selectedEmployees = state.selectedEmployees.filter(
      (emp) => !employeeIds.includes(emp.id)
    )
  },
  SET_SELECTED_WORK_TIME_REGISTER_CATEGORIES_MAP(state, payload) {
    state.worktimeRegisterCategoriesMap = payload
  },
  SET_APPLICATION_CATEGORY_OPTIONS(state, payload) {
    state.applicationCategoryOptions = payload
  },
  SET_DEPARTMENT_OPTIONS(state, payload) {
    state.departmentOptions = payload
  },
  SET_EMPLOYEE_GROUP_OPTIONS(state, payload) {
    state.employeeGroupOptions = payload
  },
  SET_POSITION_OPTIONS(state, payload) {
    state.positionOptions = payload
  },
}

export const actions = {
  setSelectedEmployees({ commit }, payload) {
    commit('SET_SELECTED_EMPLOYEES', payload)
  },
  clearSelectedEmployees({ commit }) {
    commit('CLEAR_SELECTED_EMPLOYEES')
  },
  addEmployee({ commit }, employee) {
    commit('ADD_EMPLOYEE', employee)
  },
  removeEmployee({ commit }, employeeId) {
    commit('REMOVE_EMPLOYEE', employeeId)
  },
  addEmployeeBatch({ commit }, employees) {
    commit('ADD_EMPLOYEE_BATCH', employees)
  },
  removeEmployeeBatch({ commit }, employeeIds) {
    commit('REMOVE_EMPLOYEE_BATCH', employeeIds)
  },
  setWorktimeRegisterCategoriesMap({ commit }, payload) {
    commit('SET_SELECTED_WORK_TIME_REGISTER_CATEGORIES_MAP', payload)
  },
  setApplicationCategoryOptions({ commit }, payload) {
    commit('SET_APPLICATION_CATEGORY_OPTIONS', payload)
  },
  setDepartmentOptions({ commit }, payload) {
    commit('SET_DEPARTMENT_OPTIONS', payload)
  },
  setEmployeeGroupOptions({ commit }, payload) {
    commit('SET_EMPLOYEE_GROUP_OPTIONS', payload)
  },
  setPositionOptions({ commit }, payload) {
    commit('SET_POSITION_OPTIONS', payload)
  },
}
